.App {
  font-family: sans-serif;
  text-align: center;
}
html,
body,
#root,
.bg,
canvas {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  -webkit-animation: fadein 3s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.bg {
  position: absolute;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-bar-container {
  width: 200px;
  height: 3px;
  background: #272727;
}

.loading-bar {
  height: 3px;
  background: white;
}

.dean-header {
  position: absolute;
  font-weight: 800;
  font-size: 7vw;
  line-height: 0.9em;
  letter-spacing: -5px;
  margin-left: 8%;
}

.dean-header-small {
  display: none;
}

h1 > span {
  white-space: pre;
}

.layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: linear-gradient(0deg, rgba(63, 12, 88, 0.2) 0%, transparent 100%);
  pointer-events: none;
}

span.header {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  position: absolute;
  display: inline-block;
  width: 500px;
  transform: translate3d(0, -50%, 0);
  font-size: 9em;
  line-height: 0.9em;
  pointer-events: none;
  top: 350px;
  left: 50px;
}

span.header-left {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  position: absolute;
  display: inline-block;
  transform: translate3d(0, -50%, 0);
  line-height: 1em;
  top: 200px;
  left: 60px;
  font-size: 4em;
  width: 200px;
}

.triangle-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 900px) {
  .dean-header {
    display: none;
  }
  .triangle-bg {
    height: 1000px;
  }

  .dean-header-small {
    display: block;
    position: absolute;
    font-weight: 800;
    font-size: 10vw;
    line-height: 0.9em;
    letter-spacing: -2px;
    margin-top: 80px;
  }

  .main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
    -webkit-animation: fadein 3s;
  }
}
