.socials-container {
    box-sizing: border-box;
    position: absolute;
    margin-left: 70%;
    width: 300px;
}

.socials-icon {
    font-size: 120px;
    margin-right: 28px;
    transition: color 0.4s;
}

.socials-icon:hover {
    font-size: 120px;
    color: white;
}

a {
    text-decoration: none;
    color: currentColor;
}

@media only screen and (max-width: 900px) {
    .socials-container {
      display: block;
      margin-left: 30px;
      margin-top: 700px;
    }
}